const mutations = {
  setUser: (state, payload) => {
    state.user = payload.user
    state.token = payload.user.token
    state.user.fullname = state.user.first_name + ' ' + state.user.last_name
    // console.log(state.user);
  },
  reset: (state) => {
    state.user = {},
    state.token = ""
  },
  setExitIcon: (state, payload) => {
    state.exitIcon = payload
  },
  setLoaded: (state, payload) => {
    state.loaded = payload
  },
  reset: (state) => {
    state.user = undefined
    state.token = ""
    state.loaded = false
    state.exitIcon = false
    localStorage.setItem('fivem_token', undefined)
  }
}

export default mutations