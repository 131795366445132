import apiManager from '@/http/apiManager'

const mutations = {
  init: (state, payload) => {
    state.displayArr = payload.items
  },
  reset: (state) => {
    state.displayArr = []
  },
  addItem: (state, payload) => {
    const idx = state.basket.findIndex((elem) => elem.id === payload.item.id)
    if (idx !== -1)
      state.basket[idx].quantity += payload.quantity;
    else
      state.basket.push({ ...payload.item, quantity: payload.quantity })
  },
  removeItemFromBasket: (state, payload) => {
    state.basket = state.basket.filter((elem) => elem.id != payload.id)
  },
  sendBasket: (state, payload) => {
    state.sent = false
    state.basket.forEach((elem) => {
      payload.items.push({ object: elem.id, person: payload.person, quantity: elem.quantity })
    })
    apiManager.pushChestBasket(payload).then(() => {
      state.basket = []
      apiManager.getChestByCompany(payload.company).then((res) => {
        state.sent = true
        state.chestContent = res.data.items
        state.chestContent = state.chestContent.sort((a, b) => a.id < b.id ? 1 : -1)
      })
    });
  },
  initChestContentFromApi: (state, payload) => {
    apiManager.getChestByCompany(payload).then((res) => {
      state.chestContent = res.data.items
      state.chestContent = state.chestContent.sort((a, b) => a.id < b.id ? 1 : -1)
    })
  },
  removeFromChestContent: (state, payload) => {
    state.sent = false
    apiManager.removeChestItem(payload.id).then(() => {
      state.chestContent = state.chestContent.filter((elem) => elem.id != payload.id)
      state.sent = true
    })
  }
}

export default mutations