import Vue from 'vue'
import Vuex from 'vuex'
import bills from './bills'
import hierarchy from './hierarchy'
import services from './services'
import user from './user'
import chest from './chest'
import weazel from './weazel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    bills,
    hierarchy,
    services,
    user,
    chest,
    weazel
  }
})
