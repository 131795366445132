import billCtrl from './controllers/bills'
import hierarchyCtrl from './controllers/hierarchy'
import servicesCtrl from './controllers/services'
import userCtrl from './controllers/user'
import lspdCtrl from './controllers/lspd'
import dataCtrl from './controllers/data'
import chestCtrl from './controllers/chest'
import weazelCtrl from './controllers/weazel'

export default {
  ...billCtrl,
  ...hierarchyCtrl,
  ...servicesCtrl,
  ...userCtrl,
  ...lspdCtrl,
  ...dataCtrl,
  ...chestCtrl,
  ...weazelCtrl
}