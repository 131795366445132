import { HTTP } from '../http-common';

export default {
  getStaff: (company) => { return HTTP.get(`/api/lspd/staff?company=${company}`) },
  getInterventions: (company) => { return HTTP.get(`/api/lspd/interventions?company=${company}`) },
  getMatricules: () => { return HTTP.get('/api/lspd/matricules') },
  getCharacterPhone: (character) => { return HTTP.get(`/api/lspd/informations/phone?character=${character}`) },
  setCharacterPhone: (character, phone) => { return HTTP.post('/api/lspd/informations/phone/set', { character: character, phone: phone })},
  getCharacterLicenses: (character) => { return HTTP.get(`/api/lspd/informations/licenses/get?character=${character}`) },
  setCharacterLicenses: (character, licenses) => { return HTTP.post(`/api/lspd/informations/licenses/set`, { character: character, ...licenses}) }
}