import axios from "axios";
import config from "../config.json";

let HTTP;

if (location.protocol == "http:")
  HTTP = axios.create({ baseURL: config.apiIp });
else
  HTTP = axios.create({ baseURL: config.apiUrl });

HTTP.interceptors.request.use(config => {
  config.headers["Content-Type"] = "application/json";
  config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('fivem_token')
  return config;
});

HTTP.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export { HTTP };
