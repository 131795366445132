<template>
  <div class="content-bills">
    <div class="bills-container">
      <div class="table-container">
        <Table
          :columns="columnsBills"
          :entries="_self['bills/bills']"
          tabTitle="Factures non payées"
          :clickable="true"
          :searchable="true"
          @table-cancel="(entry) => cancelBill(entry)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import apiManager from "@/http/apiManager";
import { mapGetters } from "vuex";

export default {
  methods: {
  },
  name: "Home",
  components: {
    Table,
  },
  methods: {
    cancelBill(entry) {
      this.$store.commit('bills/removeOne', entry.id)
      apiManager.deleteBill(entry.id, this['user/user'].id)
    },
    forceBill(entry) {
      apiManager.forceBill(entry.id).then(() => {
        this.allBills = this.allBills.filter((bill) => bill.id !== entry.id)
      })
    },
    forceAllBills() {
      apiManager.forceAllBills().then(() => {
        this.allBills = []
        this.$store.commit('bills/reset')
      })
    }
  },
  data() {
    return {
      loaded: false,
      exitIcon: false,
      columnsBills: [
        { key: "name", label: "Nom Prénom", class: "pad-5-0 left" },
        { key: "amount", label: "Tarif", class: "pad-5-0 right col-10" },
        { key: "cancel", label: "Annuler", class: "pad-5-0 right col-10" },
      ],
      columnsBills2: [
        { key: "name", label: "Entreprise", class: "pad-5-0 left" },
        { key: "person", label: "Nom Prénom", class: "pad-5-0 left" },
        { key: "amount", label: "Tarif", class: "pad-5-0 right col-10" },
        { key: "force", label: "Forcer", class: "pad-5-0 right col-10" },
      ],
      allBills: []
    };
  },
  computed: {
    ...mapGetters(["bills/bills", "user/user"]),
  },
  created() {
    apiManager.me().then((res) => {
      this.$store.commit("user/setUser", res.data);
      this.$store.commit('bills/setBillsFromApi', this['user/user'].id);
      apiManager.getAllBills().then((res) => {
        res.data.items.forEach((item) => {
          item.force = true
          this.allBills.push(item)
        })
      })
    }).catch(() => {
      this.$router.push("Login");
    });
    
  },
};
</script>

<style lang="scss" scoped>
.content-bills {
  width: 100%;
  height: 100%;
}

.bills-container {
  border-radius: 15px;
  background-color: $primary;
  width: calc(100% - 20px);
  height: 100%;
  padding: 0;
  margin-right: 20px;
}

.table-container {
  height: 100%;
  z-index: 1;
}

.buttons {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss">
.pad-5-0 {
    padding: 4px 0px 4px 0px !important;
    vertical-align: middle;
}
.bills-container {
    height: 100%;
}

</style>