<template>
  <div class="main-div" v-bind:class="{ 'main-div-pad': !useHomeTitle }">
    <HomeTitle class="hometitle" v-if="useHomeTitle" title="Hiérarchie" :endTitle="_self['hierarchy/employees'].length" ></HomeTitle>
    <div class="title-soft-container" v-else>
      <p class="title">Hiérarchie</p>
    </div>
    <div class="content">
      <div v-for="item in displayedEntries" :key="item.id">
        <p v-if="!item.icons" class="section-title">{{ item.title }}</p>
        <div v-else-if="item.icons" class="section-item">
          <p lass="employee">{{ item.title }}</p>
          <div class="icons" v-if="_self['user/user'].perms && _self['user/user'].perms.hierarchy">
            <DownArrow class="up-arrow action-icon" @click="() => promote(item.id)" />
            <DownArrow class="down-arrow action-icon" @click="() => demote(item.id)" />
            <RemoveIcon class="remove-icon action-icon" @click="() => fire(item.id)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NextArrow from "@/assets/icons/next.svg";
import PrevArrow from "@/assets/icons/back.svg";
import DownArrow from '@/assets/icons/down.svg';
import RemoveIcon from '@/assets/icons/remove.svg';
import { mapGetters } from 'vuex';
import apiManager from '@/http/apiManager';
import HomeTitle from '@/components/HomeTitle'

export default {
  name: "Hierarchy",
  components: {
    NextArrow,
    PrevArrow,
    DownArrow,
    RemoveIcon,
    HomeTitle
  },
  props: {
    columns: Array,
    entries: Array,
    useHomeTitle: Boolean,
    itemHeight: {type: Number, default: 47 },
  },
  data() {
    return {
      currentPage: 1,
      size: 1000000,
    }
  },
  methods: {
    nextPage: function () {
      this.getLastTitleWithoutIcons();
      if (this.currentPage * this.size < this.entries.length) this.currentPage++;
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
    },
    dispatchDisplayEvent: function (index) {
      window.dispatchEvent(
        new MessageEvent("display", { data: this.displayedEntries[index] })
      );
    },
    getLastTitleWithoutIcons: function () {
      if (this.currentPage == 1)
        return this.entries[0];
      else {
        let index = (this.currentPage - 1) * this.size;
        while (this.entries[index].icons) index--;
        return this.entries[index]
      }
    },
    promote: function (id) {      
      apiManager.promoteEmployee(this['user/user'].id, id).then((res) => {
        res.data = res.data;
        if(res.data.success) {
          this.$store.commit('hierarchy/reset');
          this.$store.commit('hierarchy/init', { employees: res.data.employees, ranks: res.data.grades })
        }        
      })
    },
    demote: function (id) {
      apiManager.demoteEmployee(this['user/user'].id, id).then((res) => {
        res.data = res.data;
        if(res.data.success) {
          this.$store.commit('hierarchy/reset');
          this.$store.commit('hierarchy/init', { employees: res.data.employees, ranks: res.data.grades })
        }
      })
    },
    fire: function(id) {
      apiManager.fireEmployee(this['user/user'].id, id).then((res) => {
        res.data = res.data;
        if(res.data.success) {
          this.$store.commit('hierarchy/reset');
          this.$store.commit('hierarchy/init', { employees: res.data.employees, ranks: res.data.grades })
        }
      })
    }
  },
  computed: {
    displayedEntries: function () {
      const returnValue = this.entries.filter((row, index) => {
        let start = (this.currentPage - 1) * this.size;
        let end = this.currentPage * this.size;
        if (index >= start && index < end) return true;
      });
      if (returnValue[0] && returnValue[0].icons) {
        returnValue.unshift(this.getLastTitleWithoutIcons());
      }
      return returnValue;
    },
    isDisabledPrev: function () {
      return this.currentPage === 1;
    },
    isDisabledNext: function () {
      return this.currentPage * this.size >= this.entries.length;
    },
    ...mapGetters([ 'user/user', 'hierarchy/employees' ])
  },
};
</script>

<style lang="scss" scoped>
.hometitle {
  box-sizing: border-box;
  height: 80px;
}

.title-soft-container {
  padding: 0 20px 0 20px;
}

.title {
  width: 100%;
  margin: 0;
  font-size: 20px;
  color: $text-primary;
  padding-bottom: 10px;
  font-family: "Gotham Light";
  font-weight: bold;
  border-bottom: 1px solid;
  border-color: $secondary
}

  .title input {
    border-bottom: none !important;
    font-size: 18px !important;
  }

.main-div {
  height: calc(100% - 20px);
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    $primary 98%,
    $secondary 98%
  );
  border-radius: 20px;
}

.main-div-pad {
  height: calc(100% - 40px) !important;
  padding-top: 20px;
  padding-bottom: 20px !important;
}

.action-icon {
  padding: 0 5px;
}

.up-arrow {
  transform: rotate(180deg);
}

.up-arrow > path {
  fill: $success;
}

.down-arrow > path {
  fill: $warning;
}

.remove-icon > path {
  fill: $danger;
  padding: 0 5px;
}

.section-title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $text-secondary;
  margin: 0;
  font-family: "Gotham Light";
  padding: 10px 0;
}

.content {
  padding: 20px 20px 0 20px;
  height: calc(100% - 75px);
  overflow-y: scroll;
}

.section-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  height: 30px;
  align-items: center;
  color: $text-primary;
  font-family: "Gotham Light";
  font-size: 18px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

p {
  margin: 0;
}

.employee {
  font-family: "Gotham Light";
  font-size: 18px;
  font-weight: bold;
  color: $text-primary;
  white-space: nowrap;
}

#buttons {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  flex-direction: row;
  height: 30px;
  position: absolute;
  bottom: 0px;
}

svg {
  fill: $text-primary;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

svg.notactive {
  fill: $inactive;
  cursor: not-allowed;
}

.current_page {
  font-size: 20px;
  font-family: "Gotham";
  font-weight: bold;
  color: $text-primary;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
