<template>
  <div class="chest-container">
    <ChestComponent />  
  </div>
</template>

<script>
import ChestComponent from '@/components/Chest';
import { mapGetters } from "vuex";

export default {
  name: "Chest",
  components: {
    ChestComponent
  },
  computed: {
    ...mapGetters([
      "user/user",
      "user/exitIcon",
    ]),
  },
  methods: {
  }
};
</script>

<style lang="scss">

.chest-container {
  width: 100%;
  height: 100%;
}
</style>
