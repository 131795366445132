<template>
    <div id="dashboard">
      <div class="card items bills">
        <Services />
      </div>
      <div class="card items hierarchy">
        <Hierarchy v-if="_self['hierarchy/displayArr'].length > 0" :columns="columnsHierarchy" :entries="_self['hierarchy/displayArr']" :pageSize="11" />
      </div>
    </div>
</template>

<script>
import Services from '@/components/Services'
import Hierarchy from '@/components/Hierarchy'
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Services,
    Hierarchy
  },
  data() {
    return {
     columnsBills: [
        { key: 'name', label: 'Nom Prénom', class: 'left' },
        { key: 'amount', label: 'Montant', class:'right col-10' },
        { key: 'cancel', label: 'Annuler', class: 'right col-10' },
      ],
      columnsHierarchy: [
        { key: 'title' },
        { key: 'icons' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "user/user",
      "bills/bills",
      "hierarchy/displayArr"
    ]),
  },
  created() {
    this.$store.commit('bills/setBillsFromApi', this['user/user'].id)
    this.$store.commit('hierarchy/initFromApi', this['user/user'])
  },
  methods: {
  }
};
</script>

<style lang="scss">


.hometitle {
  box-sizing: border-box;
  height: 80px;
}

.loaded {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}


.bills {
  width: 70% !important;
  margin-left: 20px;
}

.hierarchy {
  width: 29%;
  border-radius: 20px !important;
}


$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
  width: 300px;
  height: 300px;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: $text-secondary;
  }
  25% {
    stroke: $secondary;
  }
  50% {
    stroke: $text-secondary;
  }
  75% {
    stroke: $secondary;
  }
  100% {
    stroke: $text-secondary;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

#dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table-container {
  height: 100%;
  background: linear-gradient(180deg, $primary 98%, $secondary 98%);
  border-radius: 15px;
}

.items {
  display: block;
  width: 30%;
  height: 100%;
}
</style>
