import apiManager from '@/http/apiManager'

const mutations = {
  setBills: (state, payload) => {
    state.bills = payload
  },
  setBillsFromApi: (state, payload) => {
    state.bills = []
    apiManager.getBills(payload).then((res) => {
      for (let i = 0; i < res.data.bills.length; i++) {
        res.data.bills[i].name = res.data.bills[i].first_name + " " + res.data.bills[i].last_name;
      }
      state.bills = res.data.bills
    })
  },
  reset: (state) => {
    state.bills = []
  },
  removeOne: (state, payload) => {
    state.bills = state.bills.filter((bill) => bill.id !== payload)
  }
}

export default mutations