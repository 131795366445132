import apiManager from '@/http/apiManager'

const mutations = {
  setArticles: (state, payload) => {
    state.articles = payload
  },
  setArticlesFromApi: (state) => {
    state.articles = []
    apiManager.getArticles().then((res) => {
      state.articles = res.data.items
    })
  },
  setProfile: (state, payload) => {
    apiManager.getAuthorByCharacter(payload).then((res) => {
      state.profile = res.data.author
    })
  },
  updateProfile: (state, payload) => {
    payload.id = state.profile && state.profile.id ? state.profile.id : undefined
    apiManager.updateWeazelProfile(payload).then(() => {
      state.profile = payload
    })
  },
  setCategoriesFromApi: (state) => {
    state.categories = []
    apiManager.getCategories().then((res) => {
      state.categories = res.data.items
    })
  },
  reset: (state) => {
    state.articles = []
  },
  removeOneArticle: (state, payload) => {
    apiManager.deleteArticle(payload).then(() => {
      state.articles = state.articles.filter((article) => article.id !== payload)
    })
  },
  addArticle: (state, payload) => {
    state.articles.unshift(payload)
  }
}

export default mutations