const { HTTP } = require('../http-common');

export default { 
  getArticles: () => { return HTTP.get('/api/news/articles') },
  getArticleById: (id) => { return HTTP.get(`/api/news/article?id=${id}`) },
  getArticleByAuthor: (id) => { return HTTP.get(`/api/news/author?id=${id}`) },
  createArticle: (article) => { return HTTP.post('/api/news/article/add', article ) },
  deleteArticle: (id) => { return HTTP.delete(`/api/news/article/delete?id=${id}`) },

  getAuthorByCharacter: (id) => { return HTTP.get(`/api/news/author/character?id=${id}`) },
  updateWeazelProfile: (data) => { return HTTP.post(`/api/news/author/update`, data) }, 

  getCategories: () => { return HTTP.get('/api/news/categories') }
}