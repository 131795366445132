<template>
  <div class="dropdown-container" v-on:click="displayList">
    <input class="dropdown-input dp-input" v-bind:class="{ 'editMode-selected': selectedInput}" :placeholder="pholder" v-if="inputType" v-model="searchInput" />
    <div v-else class="dropdown-input" v-on:click="displayList">{{ pholder }}</div>
    <div class="dropdown-list" v-bind:class="{ 'hide-list': hideList }">
        <div class="dropdown-item" v-for="item in displayed" v-bind:key="item.id" v-on:click="() => setItem(item)">
            <p class="item-text">{{ item.text }}</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Dropdown",
  props: {
      placeholder: String,
      items: Array,
      inputType: Boolean,
  },
  computed: {
    displayed: function () {
      if (this.searchInput !== '')
        return this.items.filter((elem) => elem.text.toString().toLowerCase().includes(this.searchInput.toLowerCase()))
      return this.items;
    },
  },
  data() {
    return {
      hideList: true,
      pholder: this.placeholder,
      searchInput: '',
      selectedInput: false
    };
  },
  methods: {
    displayList() {
      let that = this;
      if (this.searchInput !== '')
        this.searchInput = ''
      if (this.hideList === true) {
        this.hideList = false;
        setTimeout(() => {
          document.addEventListener("click", that.closeMenu, false);
        }, 100);
      }
    },
    closeMenu() {
      document.removeEventListener("click", this.closeMenu, false);
      this.hideList = true;
    },
    setItem(item) {
      this.$emit('selected', item)
      if (this.inputType)
        this.selectedInput = true
      this.pholder = item.text
    },
    reset() {
      this.selectedInput = false
      this.pholder = this.placeholder
      this.searchInput = ''
    },
    setDispatchValue: function (value) {
      if (value && value.text) {
        this.selectedInput = true
        this.pholder = value.text
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  width: 90%;
  height: 100%;
  border: 1px solid #424243;
  cursor: pointer;
  border-radius: 5px;
  padding: 4px 0;
  color: white;
  font-family: 'Gotham Light';
  white-space: nowrap;
  line-height: 18px;
}

.dp-input {
  height: 20px;
  border: none !important;
  color: #FFF;
}

.dropdown-input::after {
    content: "";
    display: block;
    background: url('/icons/down2.svg') no-repeat;
    background-size: 11px;
    background-position: center;
    width: 20px;
    height: 20px;
    float: right;
    margin: 0;
    overflow: hidden;
}

.dropdown-input {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

input.editMode-selected::placeholder {
    color: #FFF;
}

.dropdown-list {
  position: relative;
  width: 100%;
  margin-top: 8px;
  height: auto;
  background: #4b4b4d;
  border-radius: 5px;
  max-height: 30vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

::-webkit-scrollbar-track {
    background: #4b4b4d !important;
}

.dropdown-item:hover {
  background: $secondary;
}

.dropdown-item {
  border-bottom: 1px solid #424243;
  word-break: break-word;
  white-space: break-spaces;
}

.dropdown-item:last-of-type {
  border-bottom: 0;
  border-radius: 0 0 5px 5px;
}

.dropdown-item:first-of-type {
  border-radius: 5px 5px 0 0;
}

.item-text {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px;
}

.hide-list {
    display: none;
}
</style>