<template>
  <div class="services-container">
    <div class="hometitle">
      <HomeTitle
        title="Temps de service"
        :endTitle="_self['services/displayArr'].length"
      />
    </div>
    <div class="btn primary set-zero" @click="resetPeriod">Remettre la période à zéro</div>
    <div class="table-container zero-cont">
      <Table
        :searchable="true"
        :columns="columnsServices"
        :entries="_self['services/displayArr']"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTitle from "@/components/HomeTitle";
import Table from "@/components/Table";
import apiManager from '@/http/apiManager'
export default {
  name: "Services",
  components: {
    HomeTitle,
    Table,
  },
  computed: {
    ...mapGetters(["services/displayArr", "user/user"]),
  },
  data() {
    return {
      columnsServices: [
        { key: "name", label: "Nom", class: "center col-40" },
        { key: "currentDuty", label: "En service", class: "center col-40" },
        { key: "periodTotal", label: "Période", class: "center col-40" },
        { key: "total", label: "Total", class: "center col-40" },
      ],
    };
  },
  created() {
    this.$store.commit("services/initFromApi", this["user/user"].company_id);
  },
  methods: {
    resetPeriod () { 
      apiManager.resetPeriod(this['user/user'].company_id, this['user/user'].id).then(() => {
          this.$store.commit("services/initFromApi", this['user/user'].company_id)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.services-container {
  width: 100%;
  height: 100%;
  background-color: $primary;
  border-radius: 20px;
}

.button-container {
  height: 20px;
}

.table-container {
  height: calc(100% - 100px);
  background: linear-gradient(180deg, $primary 98%, $secondary 98%);
  border-radius: 15px;
}

.btn.primary.set-zero {
    background: $danger;
    text-align: center;
    line-height: 29px;
    font-size: 16px;
    height: 30px;
    justify-content: center;
    width: fit-content;
    margin: 20px auto 0px auto;
    padding: 0 20px;
}

.table-container.zero-cont {
    height: calc(100% - 130px);
}


</style>

<style lang="scss">
#table {
  overflow-y: scroll !important;
}
</style>
