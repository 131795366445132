<template>
  <div class="chest-entry-container">
      <p>{{item.quantity}}</p>
      <p>{{ item.name }}</p>
      <div class="remove button">
        <CloseLogo class="svg close" v-on:click="removeLine"></CloseLogo>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CloseLogo from '@/assets/icons/cancel.svg'

export default {
  name: "ChestEntry",
  components: {
    'CloseLogo': CloseLogo
  },
  props: {
    item: Object
  },
  methods: {
    removeLine() {
      this.$store.commit('chest/removeItemFromBasket', this.item)
    }
  }
};
</script>

<style lang="scss" scoped>
.chest-entry-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding: 10px 0px;
  border-bottom: 1px solid #4b4b4d;
}

.remove.button {
    height: 16px;
    width: 16px;
}

.remove.button svg {
    fill: white;
}
</style>