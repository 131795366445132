import apiManager from '@/http/apiManager'

function parseDisplayArr(employees, ranks) {
  const final = []

  const empCpy = JSON.parse(JSON.stringify(employees));
  const ranksCpy = JSON.parse(JSON.stringify(ranks));

  let currentRank = ranks[ranks.length - 1];
  while (empCpy.length > 0) {
    if (currentRank == empCpy[0].company_grade_id) {
      const emp = empCpy.shift();
      final.push({ title: `${emp.first_name} ${emp.last_name}`, icons: true, id: emp.id })
    } else {
      const rank = ranksCpy.shift();
      final.push({ title: rank.name, icons: false })
      currentRank = rank.company_grade_id
    }
  }
  while (ranksCpy.length - 1 > 0) {
    const rank = ranksCpy.shift();
    final.push({ title: rank.name, icons: false })
  }
  return final;
}

const mutations = {
  init: (state, payload) => {
    state.members = payload.employees
    state.ranks = payload.ranks
    state.displayArr = parseDisplayArr(payload.employees, payload.ranks)
  },
  initFromApi: (state, payload) => {
    apiManager.getHierarchy(payload.id).then((res) => {
      state.members = res.data.employees;
      state.ranks = res.data.grades;
      state.displayArr = parseDisplayArr(res.data.employees, res.data.grades)
    })
  },
  reset: (state) => {
    state.members = [],
    state.ranks = [],
    state.displayArr = []
  }
}

export default mutations