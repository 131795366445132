import apiManager from '@/http/apiManager'

function converter(minutes)
{
  let dates=[1440,60,1]
  let output=[0,0,0];
  for(let x=0; x<3; x++) {
    while(minutes>=dates[x]) {
      minutes-=dates[x]
      output[x]++
    }
  }
  return output[0]+"j "+output[1]+"h "+output[2]+"min"
}

const mutations = {
  init: (state, payload) => {
    state.displayArr = payload.items
  },
  initFromApi: (state, payload) => {
    apiManager.getServices(payload).then((res) => {
      state.displayArr = [];
      res.data.forEach((employee) => {
        const total = converter(employee.total)
        let currentDuty = "Hors service"
        if (employee.currentDuty)
          currentDuty = converter(employee.currentDuty)
        const periodTotal = converter(employee.periodTotal)
        state.displayArr.push({ ...employee, total: total, currentDuty: currentDuty ? currentDuty : undefined, periodTotal: periodTotal })
      })
    })
  },
  reset: (state) => {
    state.displayArr = []
  }
}

export default mutations