import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login'
import Chest from '@/views/Chest'
import Bills from '@/views/Bills'

import $store from '../store/index'
import apiManager from '../http/apiManager';
import { companyId } from '../config'

let $company = companyId

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/chest',
    name: 'Chest',
    component: Chest
  },
  {
    path: '/bills',
    name: 'Bills',
    component: Bills
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath !== '/login') {
    if (localStorage.getItem('fivem_token')) {
      return apiManager.me().then((res) => {
        $store.commit('user/setLoaded', true)
        if (res.data.user.company_id === $company) {
          $store.commit("user/setUser", res.data);
          return next();
        }
        return false;
      })
    } else {
      $store.commit('user/setLoaded', true)
      return false;
    }
  } else {
    $store.commit('user/setLoaded', true)
    return next();
  }
});

export default router
