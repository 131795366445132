<template>
  <div class="chest-selector-main">
    <p class="title">{{ selectorLabel[chestKey] }}</p>
    <div class="chest-selector-container">
      <div class="dpd-container">
        <Dropdown
          :placeholder="selectorLabel[chestKey]"
          :items="items"
          :inputType="true"
          @selected="setSelected"
          ref="dropdown1"
        />
      </div>
      <div class="qty">
          <vue-numeric-input 
                    v-model="quantity"
                    :min="0"
                    :max="999"
                    :value="1"
                    :step="1"
                    controls-type="updown">
            </vue-numeric-input>
      </div>
      <div class="add-btn-container" v-on:click="addItemToBasket"></div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import apiManager from "@/http/apiManager";
import NextArrow from "@/assets/icons/next.svg";
export default {
  name: "ChestSelector",
  props: {
    chestKey: String,
  },
  components: {
    Dropdown,
    NextArrow,
  },
  data() {
    return {
      selectorLabel: {
        foods: "Nourriture",
        drinks: "Boissons",
        drugs: "Drogues",
        weapons: "Armes",
        others: "Autres",
      },
      selected: { id: -1 },
      items: undefined,
      quantity: 1,
    };
  },
  methods: {
    setSelected(selected) {
      this.selected = selected;
    },
    addItemToBasket() {
        if (this.selected.id != -1) {
            this.$store.commit('chest/addItem', { item: this.selected, quantity: this.quantity })
            this.quantity = 1
        }
    }
  },
  created() {
    apiManager.getItems().then((res) => {
      let tmp = [];
      res.data[this.chestKey].forEach((item) => {
        tmp.push({ ...item, value: item.id, text: item.name });
        tmp = tmp.sort((a, b) => a.text > b.text ? 1 : -1)
      });
      this.items = tmp;
    });
  },
};
</script>

<style lang="scss" scoped>
.chest-selector-main {
  height: 60px;
  width: 100%;
  padding-top: 20px;
}

.title {
    margin: 0;
}

.chest-selector-container {
  height: 30px;
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dpd-container {
  height: 20px;
  width: 90%;
}

.chest-selector-main .title {
    margin-left: 20px;
    font-weight: bold;
}

.buttons {
  height: 32px;
}

.addIcon {
  width: 32px;
  height: 32px;
}

.buttons > svg > path {
  fill: #fff;
}

.vue-numeric-input.updown .numeric-input {
  height: 30px;
  width: 70px;
  margin: 0 !important;
  text-align: center !important;
  padding: 5px !important;
}

button.btn.btn-decrement {
  content: "" !important;
  display: block !important;
  background: url('/icons/up.svg') no-repeat !important;
  background-size: 11px !important;
  background-position: center !important;
  height: 20px !important;
  float: left !important;
  margin: 0 !important;
  box-shadow: none;
  border: none;
  width: 70px !important;
  top: 30px !important;
  right: auto !important;
}

input.numeric-input {
  border: 1px solid #424243 !important;
  border-radius: 5px !important;
}

.vue-numeric-input.updown .numeric-input {
  height: 30px;
}

button.btn.btn-increment {
  content: "" !important;
  transform: rotate(180deg);
  display: block !important;
  background: url('/icons/up.svg') no-repeat !important;
  background-size: 11px !important;
  background-position: center !important;
  height: 20px !important;
  float: right !important;
  margin: 0 !important;
  box-shadow: none;
  border: none;
  width: 100% !important;
  width: 70px !important;
  top: -20px !important;
  right: auto !important;
}

.vue-numeric-input .btn:active {
  box-shadow: none !important;
}

.add-btn-container {
  content: ""  !important;
  display: block  !important;
  background: url('/icons/plus.svg') no-repeat  !important;
  transform: rotate(180deg)  !important;
  background-size: 20px  !important;
  background-position-y: center !important;
  height: 29px  !important;
  top: 4px;
  right: 0px !important;
  position: relative;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.add-btn-container {
  width: 50px;
  top: 1px;
}
</style>

<style lang="scss">
.vue-numeric-input.updown .btn-decrement .btn-icon,
.vue-numeric-input.updown .btn-increment .btn-icon{
    display: none !important;
}

.qty .vue-numeric-input.updown {
    width: 60px !important;
}
</style>