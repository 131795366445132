<template>
  <div id="app">
    <div class="home">
      <div class="loaded" v-if="!_self['user/loaded'] && _self.$route.fullPath !== '/login'">
        <Spinner />
      </div>
      <div style="height: 100%; width: 100%" v-else>
        <div class="head" v-if="_self['user/user'] && $route.fullPath !== '/login'" >
          <img class="logo" src="@/assets/logo.png" />
          <ProfileDisplay
            :player="_self['user/user'].fullname"
            :showIcon="_self['user/exitIcon']"
            :navObject="navigationCtrl"
          />
        </div>
        <div class="content">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '@/http/apiManager'
import Spinner from "@/assets/animations/loader.svg"
import ProfileDisplay from '@/components/ProfileDisplay'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Spinner,
    ProfileDisplay
  },
  data() {
    return {
      navigationCtrl: [
        {
          name: 'Home',
          icon: 'icons/user.svg',
          route: '/home'
        },
        {
          name: 'Chest',
          icon: 'icons/safe-box.svg',
          route: '/chest'
        },
        {
          name: 'Bills',
          icon: 'icons/writer.svg',
          route: '/bills'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['user/loaded', 'user/user', 'user/exitIcon']),
  },
  created() {
    if (this.$route.query.ig) {
      this.$store.commit('user/setExitIcon', false)
    } else {
      this.$store.commit('user/setExitIcon', true)
    }

    if (this.$route.query.token) {
      localStorage.setItem('fivem_token', this.$route.query.token)
      apiManager.me().then((res) => {
        if (res.data.user.company_id === this.$company) {
          this.$store.commit("user/setUser", res.data);
          this.$store.commit('user/setLoaded', true)
        } else {
          this.$router.push('/login')
        }
      }).catch(() => {
        this.$router.push('/login')
      })
    }

    if (localStorage.getItem('fivem_token')) {
      apiManager.me().then((res) => {
        if (res.data.user.company_id == this.$company) {
          this.$store.commit("user/setUser", res.data);
          this.$store.commit('user/setLoaded', true)
        } else {
          this.$router.push('/login')
        }
      }).catch(() => {
        this.$router.push('/login')
      })
    } else {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
body {
    margin: 0 !important;
  }
</style>

<style lang="scss" scoped>
.content {
  width: calc(100% - 20px);
  height: calc(100% - 215px);
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 175px;
}

.logo {
  margin-left: 20px;
  margin-top: 20px;
  height: 150px;
}

.home,
.loaded {
  height: 100vh;
  width: 100%;
  background: no-repeat url("./assets/background.png");
  background-size: cover;
}

</style>

<style lang="scss">

.dsp-1, .dsp-3, .dsp-4, .dsp-5, .dsp-6 {
    width: 17.6%;
}

.dsp-2 {
    width: 7%;
}

.dsp-7 {
    width: 5%;
}

.vue-numeric-input.updown .numeric-input {
    height: 30px;
    width: 70px;
    margin: 0 !important;
    text-align: center !important;
    padding: 5px !important;
}

#inputnumber  .vue-numeric-input.updown .btn-decrement .btn-icon,
#inputnumber  .vue-numeric-input.updown .btn-increment .btn-icon{
    display: none !important;
}

button.btn.btn-decrement {
    content: "" !important;
    display: block !important;
    background: url(http://lsconfidential.fr/up.svg) no-repeat !important;
    background-size: 11px !important;
    background-position: center !important;
    height: 20px !important;
    float: left !important;
    margin: 0 !important;
    box-shadow: none;
    border: none;
    width: 70px !important;
    top: 30px !important;
    right: auto !important;
}

input.numeric-input {
    border: 1px solid #424243 !important;
    border-radius: 5px !important;
}

.vue-numeric-input.updown .numeric-input {
    height: 30px;
}

button.btn.btn-increment {
    content: "" !important;
    transform: rotate(180deg);
    display: block !important;
    background: url(http://lsconfidential.fr/up.svg) no-repeat !important;
    background-size: 11px !important;
    background-position: center !important;
    height: 20px !important;
    float: right !important;
    margin: 0 !important;
    box-shadow: none;
    border: none;
    width: 100% !important;
    width: 70px !important;
    top: -20px !important;
    right: auto !important;
}

.vue-numeric-input .btn:active {
    box-shadow: none !important;
}

.add-btn-container {
    content: ""  !important;
    display: block  !important;
    background: url(http://lsconfidential.fr/plus.svg) no-repeat  !important;
    transform: rotate(180deg)  !important;
    background-size: 20px  !important;
    background-position-y: center !important;
    height: 29px  !important;
    top: 4px;
    right: 0px !important;
    position: relative;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.card {
  background-color: $primary !important;
}
</style>