<template>
  <div class="chest-container">
    <div class="overlay-sending" v-if="!_self['chest/sent']">
      <Spinner class="loader" />
    </div>
    <div class="list">
      <div class="chest-adding-zone">
        <HomeTitle title="Ajouter une saisie" />
        <input class="search" type="text" v-model="person" placeholder="Nom du propriétaire" />
        <ChestSelector chestKey="foods" />
        <ChestSelector chestKey="drinks" />
        <ChestSelector chestKey="others" />
        <div class="recap">
          <ChestEntry
            v-for="item in _self['chest/basket']"
            v-bind:key="item.id"
            :item="item"
          />
        </div>
        <div class="buttons">
          <div class="btn send" v-on:click="sendBasket">Envoyer</div>
        </div>
      </div>
      <div class="chest-zone">
        <HomeTitle title="Coffre" />
        <div class="chest-table-container">
          <Table
            class="chest"
            :columns="columns"
            :entries="_self['chest/chestContent'] ? _self['chest/chestContent'] : []"
            :searchable="true"
            :clickable="true"
            @table-cancel="deleteEntry"
            :itemHeight="65"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeTitle from "@/components/HomeTitle";
import ChestSelector from "@/components/ChestSelector";
import ChestEntry from "@/components/ChestEntry";
import Table from '@/components/Table'
import Spinner from '@/assets/animations/loader.svg'
import { mapGetters } from "vuex";
export default {
  name: "ChestComponent",
  components: {
    HomeTitle,
    ChestSelector,
    ChestEntry,
    Table,
    Spinner
  },
  computed: {
    ...mapGetters(["user/user", "chest/basket", "chest/chestContent", "chest/sent"]),
  },
  data() {
    return {
      person: "",
      columns: [
        { key: "name", label: "Objet", class: "left col-40" },
        { key: "quantity", label: "Quantité", class: "left col-40" },
        { key: "depositor", label: "Déposer par", class: "left col-40" },
        { key: "person", label: "Propriétaire", class: "left col-40" },
        { key: "cancel", label: "-", class: "right col-40" },
      ]
    };
  },
  methods: {
    deleteEntry(entry) {
      this.$store.commit('chest/removeFromChestContent', entry)
    },
    sendBasket() {
      const packet = {
        items: [],
        company: this["user/user"].company_id,
        character: this["user/user"].id,
        person: this.person.length > 0 ? this.person : "-",
      };
      this.$store.commit("chest/sendBasket", packet);
    },
  },
  created() {
    this.$store.commit("chest/initChestContentFromApi", this["user/user"].company_id);
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.chest-table-container {
  height: calc(100% - 55px);
  width: 100%;
}

.chest-adding-zone {
  background-color: $primary;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.chest-adding-zone input.search {
    width: calc(100% - 40px);
    margin-left: 20px;
}

.chest-zone {
  height: 100%;
  width: 100%;
  flex: 0 0 calc(65% - 0.5em - 10px);
  background-color: $primary;
  border-radius: 15px;
  margin-right: 20px;
  margin-left: 10px;
}

.chest-container {
  width: 100%;
  height: 100%;
}

.overlay-sending {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.loader {
  position: absolute;
  left: 40%;
  top: 25%;
  height: 20%;
  width: 20%;
}

input.search {
  border-bottom: 1px solid $secondary !important;
  height: 30px;
  margin-top: 15px;
}

.btn.send {
    background: $secondary;
    text-align: center;
    line-height: 29px;
    font-size: 16px;
    height: 30px;
    margin: 20px auto 0 auto;
}

.recap {
  overflow-y: scroll;
  height: 30%;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-top: 20px;
  border-top: 1px solid $secondary;
}
</style>