<template>
<div class="display-container">
  <div v-if="navObject" class="navigation-ctrl">
    <NavigationButton v-for="nav in navObject" v-bind:key="nav.name" :route="nav" />
  </div>
  <div class="profil-container">
    <p>Profil: <span class="bold">{{ player }}</span></p>
      <DisconnectLogo v-if="showIcon" class="svg" v-on:click="closeWebsite"></DisconnectLogo>
  </div>
</div>
</template>

<script>
import DisconnectLogo from '@/assets/icons/disconnectLogo.svg'
import NavigationButton from '@/components/NavigationButton'

export default {
  name: 'ProfileDisplay',
  components: {
    DisconnectLogo,
    NavigationButton
  },
  props: {
    player: '',
    navObject: Array,
    showIcon: Boolean
  },
  data: function() {
    return {}
  },
  methods: {
    closeWebsite: function () {
      this.$store.commit('user/reset')
      this.$store.commit('hierarchy/reset')
      this.$store.commit('bills/reset')
      this.$router.push('login')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.display-container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navigation-ctrl {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-right: 1.041vw;
  height: 6.48vh;
  margin-top: 1.85vh;
}

  .profil-container {
    background: linear-gradient(90deg, $secondary 5%, $primary 5%);
    color: $text-primary;
    height: 6.48vh;
    margin-top: 1.85vh;
    border-radius: 15px;
    text-align: right;
    padding-right: 0.78vw;
    padding-left: 1.78vw;
    font-size: 20px;
    margin-right: 1.041vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Gotham";
  }

  .bold {
    font-weight: bold;
  }

  .svg {
    width: 32px;
    height: 26px;
    margin-left: 15px;
    cursor: pointer;
  }

  .svg > path {
    fill: $secondary;
  }

</style>
