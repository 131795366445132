w<template>
  <div class="login-view">
    <div class="login-card">
      <img class="logo" src="@/assets/logo.png" />
      <div class="danger-box" v-if="danger">
        Mot de passe incorrect ou compte inexistant
      </div>
      <div class="input-container">
        <input
          id="username"
          v-model="username"
          class="input"
          type="text"
          required
        />
        <label class="label" for="username">Nom d'utilisateur</label>
      </div>
      <div class="input-container">
        <input
          id="password"
          v-model="password"
          class="input"
          type="password"
          required
          v-on:keyup.enter="sendLogin"
        />
        <label class="label" for="password">Mot de passe</label>
      </div>
      <div class="button" @click="sendLogin">Connexion</div>
    </div>
  </div>
</template>

<script>
import apiManager from "@/http/apiManager";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      danger: false,
    };
  },
  methods: {
    sendLogin() {
      if (this.username.length > 0 && this.password.length > 0) {
        apiManager.login(this.username, this.password, this.$company).then((res) => {
          localStorage.setItem("fivem_token", res.data.token);
          apiManager.me().then((res2) => {
            this.$store.commit("user/setUser", res2.data);
            this.$store.commit("user/setLoaded", true);
            this.danger = false;
            this.$store.commit("user/setExitIcon", true);
            this.$router.push(`/home`);
          });
        })
        .catch((err) => {
          this.danger = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  height: 100vh;
  width: 100%;
  background: no-repeat url("../assets/background.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
    padding: 5% 0;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 15%;
    transition: 0.3s;
    font-family: "Gotham Light";
    background: $secondary;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.danger-box {
  background-color: $secondary;
  color: $text-primary;
  margin-top: 20%;
  padding: 5%;
  border-radius: 20px;
  font-family: "Gotham Light";
  text-align: center;
  width: 200px;
}

.button:hover {
  background: #fff;
  color: $secondary;
  opacity: 1;
}

.login-card {
  background: $primary;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  padding: 5%;
  border-radius: 15px;
}

.input-container {
  position: relative;
  margin-top: 10%;
  width: 100%;
}

input {
  background: $primary;
  font-family: "Gotham Light";
  width: 100%;
  color: $text-primary;
  border: 0;
  border-bottom: 2px solid $input-color;
  outline: none;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
}

label {
  top: 0;
  left: 0;
  right: 0;
  font-family: "Gotham Light";
  color: $secondary;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  color: $input-color;
}

input,
label {
  width: 100%;
  height: 3rem;
  font-size: 1rem;
}

/* Interation */
input:valid,
input:focus {
  border-bottom: 2px solid $input-color;
}

input:valid + label,
input:focus + label {
  color: $input-color;
  font-size: 0.8rem;
  top: -30px;
  pointer-events: none;
}

.logo {
  width: 100%;
}
</style>