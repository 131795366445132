<template>
    <GPSLogo class="svg" v-if="item.key === 'gps'"></GPSLogo>
    <div class="svg-container" v-else-if="item.key === 'manageStaff'">
      <GPSLogo class="svg"></GPSLogo>
    </div>
    <ViewLogo class="svg" v-else-if="item.key === 'view'"></ViewLogo>
    <CloseLogo class="svg close" v-else-if="item.key === 'close'"></CloseLogo>
    <div v-else >{{ entry[item.key] }}</div>
</template>

<script>
import GPSLogo from '@/assets/icons/gps.svg'
import ViewLogo from '@/assets/icons/next.svg'
import CloseLogo from '@/assets/icons/cancel.svg'

export default {
  name: 'ComponentLogos',
  props: {
    entry: Object, 
    item: Object
  },
  components: {
    'GPSLogo': GPSLogo,
    'ViewLogo': ViewLogo,
    'CloseLogo': CloseLogo
  }
}
</script>

<style scoped>
  .svg-container {
    height: 26px;
    display: flex;
    justify-content: space-around; 
  }

  .svg {
    width: 32px;
    height: 26px;
  }

  .close path {
    fill: #FFF;
  }
</style>
