<template>
  <div class="nav-btn-container" v-on:click="() => $router.push(route.route)">
    <div class="icon-container">
      <img :src="route.icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationButton",
  props: {
    route: Object,
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.nav-btn-container {
  background: linear-gradient(90deg, $secondary 15%, $primary 15%);
  color: #fff;
  cursor: pointer;
  height: 100%;
  border-radius: 15px;
  text-align: right;
  padding-right: 0.78vw;
  padding-left: 1.78vw;
  font-size: 20px;
  margin-right: 1.041vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Gotham";
}

.icon-container {
  display: block;
  position: relative;
  height: 50%;
  width: 100%;
  z-index: 10000;
}

img {
  display: block;
  height: 100%;
}
</style>