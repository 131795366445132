import { HTTP } from '../http-common'

export default {
  getWanteds: () => { return HTTP.get('/api/lspd/missing') },
  addWanted: (data) => { return HTTP.post('/api/lspd/missing/add', data) },
  removeWanted: (id) => { return HTTP.delete(`/api/lspd/missing/delete?id=${id}`) },
  updateWanted: (data) => { return HTTP.post(`/api/lspd/missing/edit`, data) },
  getWantedByCharacter: (id) => { return HTTP.get(`/api/lspd/missing/character?character=${id}`) },
  removeWantedByCharacter: (id) => { return HTTP.delete(`/api/lspd/missing/delete/character?character=${id}`) },
  getReports: () => { return HTTP.get('/api/lspd/report') },
  addReport: (data) => { return HTTP.post('/api/lspd/report/add', data) },
  removeReport: (id) => { return HTTP.delete(`/api/lspd/report/delete?id=${id}`) },
  updateReport: (data) => { return HTTP.post(`/api/lspd/report/edit`, data) },
  getComplaints: () => { return HTTP.get('/api/lspd/complaints') },
  addComplaint: (data) => { return HTTP.post('/api/lspd/complaints/add', data) },
  removeComplaint: (id) => { return HTTP.delete(`/api/lspd/complaints/delete?id=${id}`) },
  updateComplaint: (data) => { return HTTP.post(`/api/lspd/complaints/edit`, data) },
  addCriminalRecord: (record) => { return HTTP.post('/api/lspd/criminal/add', record) },
  getDispatchs: (company) => { return HTTP.get(`/api/lspd/dispatch/active?company=${company}`) },
  addDispatch: (dispatch) => { return HTTP.post('/api/lspd/dispatch/add', dispatch) },
  removeDispatch: (id) => { return HTTP.delete(`/api/lspd/dispatch/delete?id=${id}`) },
  getVehicles: () => { return HTTP.get('/api/lspd/vehicles') },
  getStolenVehicles: () => { return HTTP.get('/api/lspd/vehicles/stolen') },
  changeStolenStatus: (currentStatus, plate) => { return HTTP.post('/api/lspd/vehicles/setstolen', { currentStatus: currentStatus, plate: plate } )},
  getCrimes: () => { return HTTP.get(`/api/lspd/crimes`) },
  getCrimesCharacters: () => { return HTTP.get('/api/lspd/crimes/characters') },
  getCriminalRecords: () => { return HTTP.get('/api/lspd/criminal') },
  getCriminalRecordsByCharacter: (id) => { return HTTP.get(`/api/lspd/criminal/character?id=${id}`) },
  getCrimesByCharacterAndType: (id, type) => { return HTTP.get(`/api/lspd/criminal/ctype?character=${id}&type=${type}`) },
  removeCriminalRecord: (id) => { return HTTP.delete(`/api/lspd/criminal/delete?id=${id}`) },
  getCrimesByType: (type) => { return HTTP.get(`/api/lspd/crimes/types?type=${type}`) },
  pushCrimes: (characterId, crimes) => { return HTTP.post('/api/lspd/criminal/add', { character: characterId, crimes: crimes })}
}