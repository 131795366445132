<template>
  <div class="title-container">
    <div class="title-subcontainer">
      <div class="title">
        <p>{{ title }}</p>
      </div>
      <div v-if="endTitle && endTitle.logo" class="title-end" v-on:click="onClick()">
        <ComponentLogos :item="endTitle"></ComponentLogos>
      </div>
      <div v-else class="title-end">
        {{ endTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import ComponentLogos from '@/components/ComponentLogos'

export default {
  name: 'HomeTitle',
  props: {
    title: String,
    // eslint-disable-next-line vue/require-prop-type-constructor
    endTitle: '',
    event: String
  },
  components: {
    ComponentLogos
  },
  data: function() {
    return {}
  },
  methods: {
    onClick: function () {
      window.dispatchEvent(new MessageEvent(this.event))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
  .title-container {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .title-subcontainer {
    background: $lighter;
    border-radius: 15px;
    width: 85%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: "Gotham";
  }

  .title {
    display: flex;
    align-items: center;
    text-align: center;
    width: 85%;
    justify-content: center;
    font-weight: bold;
    color: $text-primary;
  }

  .title input {
    border-bottom: none !important;
    font-size: 18px !important;
  }

  .title-end {
    width: 16%;
    justify-self: end;
    justify-content: center;
    border-radius: 0% 15px 15px 0%;
    background: $secondary;
    height: 100%;
    color: $text-primary;
    font-weight: bold;
    align-items: center;
    display: flex;
  }

  .title-end svg {
    cursor: pointer;
  }
</style>
