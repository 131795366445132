<template>
  <div class="table">
    <p class="tab-title">{{ tabTitle }}</p>
    <input class="search" v-if="searchable" v-model="searchQuery" placeholder="Recherche">
    <div id="table" ref="table">
      <div v-if="displayedEntries.length === 0 && !display">
        <Spinner class="loader" />
      </div>
      <div class="bills-container" v-else>
          <div class="buttons force">
            <div class="btn btn-danger" v-if="forceBills" v-on:click="() => dispatchClick('forceAllBills', entry)">
              Forcer toutes les factures
            </div>
          </div>
          <div id="table">
            <table>
              <thead>
                  <tr>
                    <th v-for="item in columns" :key="item.key" v-bind:class="item.class"> {{ item.label }} </th>
                  </tr>
              </thead>
              <tbody>
                <div v-bind:class="{ clickable: clickable }" style="width: 100%; height: 100%; display: contents">
                  <tr ref="line" v-for='entry in displayedEntries' :key="entry.id" v-on:click="() => dispatchClick('clicked', entry)" v-bind:class="entry.class">
                    <td v-for='item in columns' :key="item.key" v-bind:class="item.class" :colspan="entry[item.key] == 'Définit par le juge' ? '3' : '1'">
                      <div v-if="item.key === 'cancel'">
                        <DeleteBtn class="delete-btn" v-on:click="() => dispatchClick('table-cancel', entry)"/>
                      </div>
                      <div v-if="item.key === 'dispatch-actions'">
                        <DeleteBtn class="delete-btn" v-on:click="() => dispatchClick('dispatch-delete', entry)"/>
                        <EditBtn class="edit-btn" v-on:click="() => dispatchClick('dispatch-edit', entry)"/>
                      </div>
                      <div v-if="item.key === 'color'">
                        <div class="color-circle" :style="dynamicColor(entry)"></div>
                      </div>
                      <div v-else-if="item.key === 'stolen-btn'">
                          <NextArrow v-on:click="() => dispatchClick('stolen', entry)" />
                      </div>
                      <div v-else-if="item.key === 'unstolen-btn'">
                        <PrevArrow v-on:click="() => dispatchClick('stolen', entry)" />
                      </div>
                      <div v-else-if="item.key === 'force'">
                        <RemoveBtn class="delete-btn" v-on:click="() => dispatchClick('force', entry)" />
                      </div>
                      <div v-else-if="item.key === 'location'">
                        <GPSBtn class="gps-btn" v-fivem-click="() => dispatchClick('setgps', entry)" />
                      </div>
                      <span v-else>{{ entry[item.key] }}</span>
                    </td>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
          <div id="buttons" v-if="searchedEntries.length > size">
            <PrevArrow v-fivem-click="() => prevPage()" :class="{notactive: isDisabledPrev}" />
            <div class="current_page">{{ currentPage }}</div>
            <NextArrow :class="{notactive: isDisabledNext}" v-fivem-click="() => nextPage()" />
          </div>
          </div>
        </div>
    </div>
</template>

<script>
import NextArrow from "@/assets/icons/next.svg";
import PrevArrow from "@/assets/icons/back.svg";
import DeleteBtn from "@/assets/icons/delete.svg";
import RemoveBtn from "@/assets/icons/check.svg";
import GPSBtn from '@/assets/icons/gps.svg';
import EditBtn from '@/assets/icons/edit.svg';
import { mapGetters } from "vuex";
import Spinner from "@/assets/animations/loader.svg";

export default {
  components: {
    NextArrow,
    PrevArrow,
    DeleteBtn,
    RemoveBtn,
    EditBtn,
    GPSBtn,
    Spinner,
  },
  props: {
    columns: Array,
    entries: Array,
    itemHeight: { type: Number, default: 45 },
    tabTitle: String,
    clickable: Boolean,
    searchable: Boolean,
    forceBills: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: "",
      size: 0,
      display: false,
    };
  },
  methods: {
    nextPage: function () {
      if (this.currentPage * this.size < this.searchedEntries.length)
        this.currentPage++;
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
    },
    dispatchClick: function (event, entry) {
      if (this.clickable) {
        this.$emit(event, entry);
      }
    },
    dynamicColor: function (entry) {
      return (
        "background-color: rgb(" +
        entry.colorPrimary.r +
        "," +
        entry.colorPrimary.g +
        "," +
        entry.colorPrimary.b +
        ");"
      );
    },
  },
  async mounted() {
    setTimeout(() => {
      this.display = true;
    }, 2500);
    await this.$nextTick();
    this.size = Math.trunc(
      document.querySelector("#table").clientHeight / this.itemHeight
    );
    if (this.size * this.itemHeight > document.querySelector("#table").clientHeight)
      this.size -= 1;
  },
  computed: {
    ...mapGetters(["user/user "]),
    displayedEntries: function () {
      return this.searchedEntries.filter((row, index) => {
        let start = (this.currentPage - 1) * this.size;
        let end = this.currentPage * this.size;
        if (index >= start && index < end) return true;
      });
    },
    isDisabledPrev: function () {
      return this.currentPage === 1;
    },
    isDisabledNext: function () {
      return this.currentPage * this.size >= this.searchedEntries.length;
    },
    searchedEntries() {
      this.currentPage = 1;
      return this.entries.filter((entry) => {
        for (let i = 0; i < this.columns.length; i++) {
          if (
            typeof entry[this.columns[i].key] === "string" ||
            typeof entry[this.columns[i].key] === "number"
          ) {
            if (
              entry[this.columns[i].key]
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            )
              return true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-title {
  width: 100%;
  margin: 0;
  font-size: 20px;
  color: $text-primary;
  font-family: "Gotham Light";
  font-weight: bold;
  border: none;
}

svg.edit-btn {
    height: 17px;
    margin-bottom: 5.5px;
    margin-left: 3px;
}

div.color-circle {
  width: 70px;
  height: 30px;
  border-radius: 5px;
}

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.delete-btn > path {
  fill: #db1e39;
}

.delete-btn {
  width: 27px;
  height: 27px;
}

.left {
  text-align: left;
}

.loader {
  width: 20%;
  height: 100%;
}

.right {
  text-align: right;
}

th.col-10 {
  width: 10%;
}

th.col-20 {
  width: 20%;
}

th.col-30 {
  width: 30%;
}

th.col-35 {
  width: 35%;
}

th.col-40 {
  width: 40%;
}

th.col-50 {
  width: 50%;
}

td.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.largepadding {
  padding: 15px 30px;
}

.less-padding {
  padding: 10px 5px;
}

.hidden {
  display: none;
}

#table {
  width: 100%;
  height: calc(100% - 35px) !important;
  text-align: center;
  overflow-y: hidden;
  padding-right: 15px;
}

table {
  table-layout: fixed;
  width: 100%;
  border-radius: 3px;
  border-collapse: collapse;
}

tbody tr {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-position: 0 98%, 0 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 20px;
  -moz-background-size: 100% 2px;
  background-size: 100% 2px;
}

th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $text-secondary;
}

td {
  color: $text-primary;
  font-size: 18px;
  font-family: "Gotham Light";
}

th,
td {
  padding: 10px 0px;
  font-family: "Gotham Light";
}

#buttons {
  margin-top: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.btn-danger {
  background-color: $danger !important;
  color: $text-primary;
  z-index: 150;
  width: 35% !important;
  margin-left: 10px;
  padding: 5px;
}

.table {
  height: 100%;
  display: flex;
  padding: 15px 20px 20px 20px;
  flex-direction: column;
  border-radius: 15px;
  box-sizing: border-box;
}

.card tr {
  font-size: 14px;
}

.btn {
  background: none;
  border: 0;
  font-family: "Gotham Light";
  color: $secondary;
  border-radius: 36px;
  width: 100px;
  font-weight: bold;
  cursor: pointer;
  color: $text-primary;
}

svg {
  fill: $text-primary;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

svg.notactive {
  fill: $inactive;
  cursor: not-allowed;
}

.current_page {
  font-size: 20px;
  font-family: "Gotham";
  font-weight: bold;
  color: $text-primary;
  padding-left: 20px;
  padding-right: 20px;
}

.logo-container {
  width: 15%;
}

.btn:focus {
  outline: none;
}

:focus {
  outline: none;
}

.svg {
  width: 24px;
  height: 24px;
}

.svg-inline--fa {
  vertical-align: -0.4em !important;
}

.button {
  padding: 5px;
  background-color: $secondary;
  color: $text-primary;
  border-radius: 10px;
  cursor: pointer;
}

input:valid,
input:focus {
  border-bottom: 1px solid #4b4b4d;
}

input.search {
  border-bottom: 1px solid $secondary !important;
  height: 30px;
}

.force {
    position: absolute;
    top: 150px;
    right: 20px;
    width: 260px;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    height: 27px;
}

.force .btn {
    width: 100% !important;
    margin: 0 !important;
    padding: 5px 0px;
    border-radius: 15px;
}
</style>