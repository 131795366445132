import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNumericInput from 'vue-numeric-input';
import config from './config.json'
 
Vue.use(VueNumericInput)

Vue.config.productionTip = false

Vue.prototype.$company = config.companyId

const applyClickAllNodes = (el, onClick) => {
  for (let i = 0; i < el.children.length; i++) {
    el.children[i].onclick = onClick
    applyClickAllNodes(el.children[i], onClick)
  }
}

Vue.directive('fivem-click', {
  bind(el, binding) {
    const onClick = (e) => { binding.value(); e.stopPropagation() }
    el.onclick = onClick
    applyClickAllNodes(el, onClick)
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
